.logo {
  display: none;
//  @include media-breakpoint-up(sm) {
//    display: block;
//    float: left;
//    min-width: 160px;
//    margin-left: -100px;
//  }
  @include media-breakpoint-up(md) {
    display: block;
    float: left;
    min-width: 160px;
//    margin-left: -10px;
  }
  img {
    max-width: unset;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.logo-mobile {
  display: flex;
  align-items: center;
  float: left;
  justify-content: flex-start;
  width: 40px;
  padding: 10px 0 10px 0;
  @include media-breakpoint-up(md) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}