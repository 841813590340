.main-menu {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    margin-left: 20px;
    float: left;
   }
  > ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > li {
      list-style: none;
      font-size: 1rem;

      &.show .dropdown-toggle::after {
        transform: rotate(180deg);
        transition: transform .3s ease-out; 
      }

      & .dropdown-toggle::after {
          transform: rotate(0deg);
          transition: transform .3s ease-out; 
      }

      > a {
        padding: 10px 12px 10px 12px;
        display: inline-block;
        font-weight: normal;
        text-decoration: none;
        color: $primary;
        &:hover, &:focus-visible {
          text-decoration: none;
          outline: none;
        }
      }
      &.active {
        > a {
          font-weight: bold;
          text-decoration: none;
          &:hover {
            text-decoration: none;
            transition: all 225ms ease-in 0s;
          }
        }
      }

      li .dropdown-toggle::after {
        transition: transform .3s ease-out; 
      }

      &.show {
        & div.dropdown-menu {
          & a.dropdown-item {
              line-height: 24px;
              padding-top: 8px;
              padding-bottom: 8px;       
          }
    
          & a.dropdown-item picture {
            max-width: 24px;
            height: auto;         
            & img {
              max-width: 24px; 
              height: auto;
            }
          }        
        }
      }
 
    }

  }

}