.stickySocial {
    transition: .3s;
    position: fixed;
    left: 300px;
    top: 90px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 0;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    left: auto;
    background-color: transparent;

    & ul {
        position: absolute;
        left: -5%;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        font-size: 18px;

        & li {
            color: $primary;
            letter-spacing: .5px;
            font-size: 16px;
            line-height: 24px;        
        }
    }

    & a {
        margin-bottom: 12px;
        display: block;
        position: relative;
        top: 0;    
    }
}