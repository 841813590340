.strip {
  background: white;
  background-repeat: no-repeat;
  & h2 {
    font-size: 2.66rem;
    line-height: 1.17;
  }
  & h3 {
      font-size: 2rem;
  }
}

.strip-white {
  background-color: white;
}

.strip-transparenttitle {
  background-color: unset !important;
  h2 {
    font-size: 2.66rem;
    line-height: 1.17;
  }
  p {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: -0.2px;
    text-align: center;  }
}

.strip-horizontallist {
  background-color: unset !important;
  & h2 {
    font-size: 2.66rem;
    line-height: 1.17;
  }
  & .horizontal-list {
    & div {
      width: 150px !important;
      height: 150px !important;
    }
  }
}

.strip-greyhorizontallist {
  background-color: $white-offset;
  & h2 {
    font-size: 2.66rem;
    line-height: 1.17;
  }
  & .horizontal-list {
    background-color: unset !important;  
    & div {
      width: 150px !important;
      height: 150px !important;
    }
  }
}
.strip-grey {
  background-color: $white-offset;
}

.strip-darkgrey {
  background-color: $gray-700;
}

.strip-diagonal {
  transform: skewY(5deg);
  padding-bottom: 50px;
  margin-bottom: 65px;
  > div {
    transform: skewY(-5deg);
  }
}
.strip-primary-gradient {
  background-image: linear-gradient(to right, $primary, $secondary);
}
.strip-primary-gradient-top-bottom {
  background-image: linear-gradient(to bottom, $primary, $secondary);
}
.strip-primary {
  background-color: $primary;
}
.strip-secondary {
  background-color: $secondary;
}

.strip-diagonal-right {
  margin-top: -100px;
  transform: skewY(-5deg);
  padding-bottom: 100px;
  > div {
    transform: skewY(5deg);
  }
}

.strip-diagonal-left {
  margin-top: -100px;
  transform: skewY(5deg);
  padding-bottom: 100px;
  > div {
    transform: skewY(-5deg);
  }
}

.strip-bg-contain {
  background-size: contain;
}

.strip-bg-cover {
  background-size: cover;
}
