/* nunito-sans-300 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-sans-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v6-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-300italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/nunito-sans-v6-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v6-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v6-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v6-latin-300italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-sans-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v6-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/nunito-sans-v6-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v6-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v6-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v6-latin-italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-800 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/nunito-sans-v6-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v6-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v6-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v6-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v6-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
}