$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px,
);

$container-max-widths: (
  sm: 566px,
  md: 740px,
  lg: 960px,
  xl: 1140px,
);

$grid-gutter-width: 30px;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: 10px,
    2: 20px,
    3: 30px,
    4: 40px,
    5: 50px,
    6: 60px,
    7: 70px,
    8: 80px,
    9: 90px,
    10: 100px,
  ),
  $spacers
);

// Colors Variables

$bg-blue:    rgba(0, 7, 255, 0.15);
$bg-indigo:  rgba(112, 16, 242, 0.15);
$bg-purple:  rgba(111, 66, 193, 0.15);
$bg-pink:    rgba(232, 62, 140, 0.15);
$bg-red:     rgba(220, 53, 69, 0.15);
$bg-orange:  rgba(253, 126, 20, 0.15);
$bg-yellow:  rgba(255, 193, 7, 0.15);
$bg-green:   rgba(40, 167, 69, 0.15);
$bg-teal:    rgba(32, 201, 151, 0.15);
$bg-cyan:    rgba(23, 162, 184, 0.15);
$bg-white:   rgba(255, 255, 255, 0.15);
$bg-black:   rgba(0, 0, 0, 0.15);

// Carousel

$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;

$carousel-control-icon-width:        20px !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Modal
$modal-fade-transform:                scale(.8);
