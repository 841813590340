
.carousel .carousel-control-next,
.carousel .carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}

.carousel .carousel-control-prev {
    left: -100px;
  @include media-breakpoint-down(xs) {
    left: -20px;
  }
  @include media-breakpoint-up(sm) {
    left: -60px;
  }
  @include media-breakpoint-up(md) {
    left: -100px;
  }
}

.carousel .carousel-control-next {
    right: -100px;
  @include media-breakpoint-down(xs) {
    right: -60px;
  }
}

.carousel .sr-only {
  width: 0px;
  color: transparent;
}

html {
  scroll-behavior: smooth;
}

.carousel-photo {
    height: 112px;
    width: 80px;
    padding-top: 32px;
    margin-left: 16px;
    float: left;
    & img, & picture {
        width: 100%;
    }
}

.carousel-logo {
  vertical-align: middle;
  text-align: center;
  display: block;
  height: 70px;
  img, picture {
    max-height: 50px;
  }
  & .carousel-bottom {
    position: absolute;
    bottom: 15px;
    text-align: center;
    right: 60px;
  }
  & .carousel-quote {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 33.33%;
  }
  @include media-breakpoint-only(xs) {
  & .carousel-quote {
    width: 10%;
    right: 50px;
  }
  }
}

.carousel-name {
  float: left;
  margin-top: 62px;
  margin-left: 10px;
  width: calc(100% - 122px);
  h2 {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 1;
  }
  p {
    font-size: 12px;
    line-height: 1;
  }
}

.carousel-feedback {
  font-style: italic;
}

@include media-breakpoint-only(md) {
    /* Show 4th slide on md if col-md-4*/
    .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -33.3333%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
}

@include media-breakpoint-only(sm) {
    /* Show 3rd slide on sm if col-sm-6*/
    .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -50%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
}

@include media-breakpoint-only(xs) {
    .carousel-item {
        padding-left: 60px;
        padding-right: 40px;
    }
}

@include media-breakpoint-up(sm) {
    .carousel-item {
        margin-right: 0;
    }
    /* show 2 items */
    .carousel-inner .active + .carousel-item {
        display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
        transition: none;
    }
    .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* farthest right hidden item must be also positioned for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    /* right or prev direction */
    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}

@include media-breakpoint-up(md) {
    /* show 3rd of 3 item slide */
    .carousel-inner .active + .carousel-item + .carousel-item {
        display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
        transition: none;
    }
    .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* right or prev direction */
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}

@include media-breakpoint-up(lg) {
    /* show 4th item */
    .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
        display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
        transition: none;
    }
    /* Show 5th slide on lg if col-lg-3 */
    .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -25%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
    /* left or forward direction */
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* right or prev direction //t - previous slide direction last item animation fix */
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}
