.contactform {
	padding: 20px 10px 10px 10px;
    background-color: $bg-blue;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

    & h4 {
    	text-align: center;
    }

    & form input[type="checkbox"] {
    	float: left;
    	margin-top: 4px;
    	margin-right: 5px;
    }

    & form input[required] {
    	border-color: rgba(200, 0, 0, 0.5);;
    	border-width: 2px;
    }

    & form .custom-control-label {
    	display: flex !important;
    }

    & button[type="submit"] {
    	margin-top:  20px;
    	display: flex;
    }

    & button.dataloading {
      background-image: url('/images/loading.gif') !important;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }

    & .subscribermail {
    	opacity: 0;
    	width: 0px !important;
    	height: 0px;
    	padding: 0 !important;
    	margin: 0 !important;
    	border: none;
    }
}

#successForm {
  opacity:  0;
  height: 0px;
}

#errorForm {
  opacity:  0;
  height: 0px;
}

#errorCorporateForm {
  opacity:  0;
  height: 0px;
}

.contactformdesc {
  margin-top: 40px;
  & .right {
    margin-right: 64px;
  }
  & .left {
    margin-left: 64px;
  }
  & div {
    align-items: center !important;
  }
}