.header {
  color: $primary;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //box-shadow: 0 1px 15px rgba(50, 50, 93, 0.2);
  //border-bottom: 1px solid $white-offset;
  //padding: 10px 0px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  position: fixed;
  z-index: $zindex-fixed;
  &.container {
    //display: flex;
    justify-content: space-between;
    align-items: center;
    &.logo {
      img {
        height: 40px;
      }
    }
  }
  &.header-absolute {
    position: absolute;
    z-index: 10;
    width: 100%;
  }
  &.scrolled {
    transition: .7s;
    background-color: #ffffff;
    box-shadow: 0 1px 15px rgba(50, 50, 93, 0.2);
    //border-bottom: 1px solid $white-offset;
  }
}
.lock-scroll {
  .header {
    &.header-absolute {
      position: static;
    }
  }
}

