.subscribe-newsletter {
	max-width: 350px;
	margin: auto;
	color: $gray-500;
	& .subscribenb-inputwrapper {
		text-align: left;
		height: 48px;
	}
	& form.subscribenb-form input[type="email"] {
    	text-align: center;
    	border: 1px solid rgba(0, 0, 40, 0.04);
    	border-radius: 4px 0 0 4px;
    	padding: 8px 4px;
    	width: 100% !important;
    	height: 48px;
    	margin: 0;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	box-sizing: border-box;
    	display: inline-block;
    	float: left;
		color: $gray-600;
		font-style: normal;
    	font-variant: normal;
    	font-weight: 400;
    	font-size: 18px;
    	line-height: 1.44;
    	&:focus, &:focus-visible {
			outline: $gray-200 auto 2px;
			box-shadow: initial;
		}
    }

    & form.subscribenb-form .input-group {
    	width: calc(100% - 44px) !important;
    	float: left;
    }

    & form.subscribenb-form input[type="email"].subscribermail {
		opacity:  0;
		width: 0px !important;
		height: 0px;
		padding: 0 !important;
		margin: 0 !important;
		border:  none;
    }
    & button.subscribenb-button {
    	float: left;
    }
    & button.dataloading {
    	background-image: url('/images/loading.gif') !important;
    }

::-webkit-input-placeholder {
	color: #777777;
}
::-moz-placeholder {
	color: #777777;
}
:-ms-input-placeholder {
	color: #777777;
}
:-moz-placeholder {
	color: #777777;
}

}