// Colors
$primary: #091e42;
$secondary: #f88379;
//$black: #2f2f41;
$black: #1d1d1d;
$background-button: #611f69;
$white: #ffffff;
$white-offset: #fff6f8;
$steel: #5C5A5A;

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: lighten($primary, 10%);
$link-hover-decoration: underline;

// Fonts
@import 'fonts/font-nunito';
$font-family-base: 'Nunito Sans', Helvetica, Arial, sans-serif, -apple-system;
$font-family-heading: 'Nunito Sans', serif, -apple-system;

@import 'fonts/fontawesome/fontawesome';
@import 'fonts/fontawesome/brands';
@import 'fonts/fontawesome/solid';

// Footer
$footer-background-color: $primary;
$footer-text-color: $white;
$sub-footer-background-color: darken($primary, 10%);
$sub-footer-text-color: $white;

// Bootstrap
@import 'bootstrap-variables';
@import 'bootstrap/bootstrap-reboot';
@import 'bootstrap/bootstrap-grid';
@import 'bootstrap/utilities/text';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/carousel';
@import 'bootstrap/modal';
@import 'bootstrap/dropdown';
@import 'bootstrap/images';
@import 'bootstrap/utilities/borders';
@import 'bootstrap/utilities/shadows';
@import 'bootstrap/utilities/sizing';
@import 'bootstrap/forms';

// @import 'bootstrap/bootstrap'; // Uncomment this line to import the entire Bootstrap library


// Libraries
@import 'libraries/hamburgers/hamburgers';

// Components
@import 'components/type';
@import 'components/page';
@import 'components/header';
@import 'components/footer';
@import 'components/sub-footer';
@import 'components/logo';
@import 'components/main-menu';
@import 'components/right-menu';
@import 'components/main-menu-mobile';
@import 'components/hamburger';
@import 'components/buttons';
@import 'components/buttons-header';
@import 'components/call';
@import 'components/title';
@import 'components/content';
@import 'components/horizontal-list';
@import 'components/intro';
@import 'components/intro-image';
@import 'components/strip';
@import 'components/feature';
@import 'components/social';
@import 'components/twocolumns';
@import 'components/carousel';
@import 'components/blockitems';
@import 'components/blogitems';
@import 'components/cookie-consent';
@import 'components/breadcrumb_blogs';
@import 'components/blogtitle';
@import 'components/subscribenewsletter';
@import 'components/stickysocial';
@import 'components/blog_downloadform';
@import 'components/formular';

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: $font-family-base;
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 1.3;
  }
  @include media-breakpoint-down(sm) {
    min-width: 576px;
  }

  .grecaptcha-badge {
    display:none !important;
  }
}
