.cookie-consent.collapse {
  visibility: hidden;
  -webkit-transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;
  transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;
  height: 0px;
}

.cookie-consent.collapse.show {
  visibility: visible;
  height: unset;
  -webkit-transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;
  transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;

  & ul {
    padding: revert;
    margin-bottom: 15px;
  }
}
