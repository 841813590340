.buttons-header {
  padding-top: 20px;
  margin-left: 5px;
  & .buttonbox {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin-right: 14px;
    white-space: nowrap;
    z-index: 1;
  }
  & .buttonbox .button {
    background: $background-button;
    &:hover {
      color: $white;
      background-color: $primary;
      //transform: translateY(-1px);
      //box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      text-decoration: none;
    }
  }
  & .buttonbox svg.transparent {
    fill: $background-button;
    width: auto;
    margin-top: -3px;
    position: static;
    left: auto;
    top: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  & a.transparent {
    white-space: nowrap;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    border: 1px solid #000;
    //box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background: $white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: $background-button;
    text-decoration: none;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    &:hover {
      //color: $white;
      //background-color: lighten($background-button, 10%);
      //border: 2px solid lighten($background-button, 10%);
      //transform: translateY(+1px);
      box-shadow: inset 0 0 0 1px $background-button;
      text-decoration: none;
    }
  }
}
