.blockitems-image {
	padding-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 10px;
	margin: auto;
	width: 100%;
	text-align: center;
	& img {
		width: auto;
		height: 180px;
		max-width: 220px;
	}
}

.blockitems-description {
	padding-top: 20px;
	padding-bottom:  20px;
}

.blockitems-item {
	& h2 {
		text-align: center;
	}
	& div {
		align-items: center;
		text-align: center;
		margin:  auto;
	}
}