.footer {
  background: $footer-background-color;
  padding-top: 15px;
  padding-bottom: 15px;
  .footer-mainmenu {
  }
  .footer-footermenu {
    @include media-breakpoint-up(xl) {
      max-width: 275px;
    }
  }
  .footer-copyrightmenu {
  margin-top: 16px;
    color: white;
    a {
        color: white;
        text-decoration: none;
    }
    & .divider {
      margin: 0 7px;
    }
  }
  .footer-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
  }
  .footer-footerinfo {
    & .logo {
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
        float: left;
        width: 160px;
        margin-left: 0;
      }
      @include media-breakpoint-down(lg) {
        display: block;
        float: left;
        width: 160px;
        margin-left: 0;
      }
      img {
        width: 100%;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    & .footer-social {
      a {
        margin: 8px;
        font-size: 16px;
        display: inline-block;
        width: 40px;
        height: 40px;
        font-weight: bold;
        color: white;
        border-radius: 50%;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        line-height: 40px;
        -webkit-transition: background .3s ease-out;
        transition: background .3s ease-out;
        background-color: rgba(255,255,255,0.15);
        &:hover {
          background-color: rgba(255,255,255,0.5);
          color: white;
        }
      }
    }
    & .footer-contactmail {
      color: white;
      a {
        color: white;
        text-decoration: none;
      }    
    }
    & .footer-phonenumber {
      padding: 4px 2px;
      color: white;
      margin: 0 auto; 
      a {
        color: white;
        margin: 0 5px; 
        text-decoration: none;
      }
      & .select_arrow {
        display: inline-block;
        margin-left: 4px;
        width: 12px;
        cursor: pointer;
      }  
    }

    & .footer-phonenumbers {
      padding: 4px 2px;
      color: white;
      margin: 0 auto; 
      li {
        display: inline-flex;
        width: 100%;
      }
      a {
        color: white;
        margin: 0 5px; 
        text-decoration: none;
        padding: 0;
      }
    }

    & .footer-phonenumbers.collapse {
      visibility: hidden;
      -webkit-transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;
      transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;
      height: 0px;
    }

    & .footer-phonenumbers.collapse.show {
      visibility: visible;
      height: unset;
      -webkit-transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;
      transition: height .9s linear, opacity .9s linear, padding-top .9s linear, padding-bottom .9s linear, border-top-width .9s linear, border-top-width .9s linear;
    }
  }

  .footer-title {
    color: $white;
    font-size: 1.3rem;
    font-family: $font-family-heading;
    font-weight: bold;
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      color: $footer-text-color;
      font-size: 1rem;
      margin-bottom: 8px;
      a {
        color: $footer-text-color;
        text-decoration: none;
        padding: 12px 14px 12px 0;
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li.button {
      position: relative;
      display: inline-block;
      overflow: hidden;
      color: $footer-background-color;
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      padding: 0 25px;
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      text-decoration: none;
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease;
      width: 100%;
      &:hover {
        background: initial;
      }
      a {
        background: $white;
        border-radius: 4px;
        padding: 0;
        color: $footer-background-color;
        width: 100%;
        text-align: center;
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        &:hover {
          color: $white;
          background: lighten($footer-background-color, 10%);
          text-decoration: none;
        }
      }
    }
  }
}
