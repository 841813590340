.horizontal-list {
  height: 100%;
  //border: 1px solid $white-offset;
  //border-radius: 3px;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  //flex-wrap: wrap;
  & div {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    display: flex;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
    &.black-and-white {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
      filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
    }
    & picture {
      display: flex;
    }
    & img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
