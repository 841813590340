.right-menu {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
//    margin-right: -80px;
    float: right;
    height: 40px;
  }
  align-items: center;
  & div {
    margin-left: 10px;
  }

  & .language {
    margin-right: 10px;
  }

  @include media-breakpoint-down(lg) {
    & .buttonbox {
      display: none;
    }
  }


  @include media-breakpoint-up(lg) {

    & .dropdown {
      & .dropdown-menu {  
        display:block;
        opacity: 0;
        visibility: hidden;
        transition:.5s;
        margin-top:1em;
      }

      & .dropdown-menu {
        & .fade-down {
          top:80%;
          transform: rotateX(-75deg);
          transform-origin: 0% 0%;
        }
        & .fade-up {
          top:180%;
        }
      }

      & .dropdown-toggle::after {
        transform: rotate(0deg);
        transition: transform .3s ease-out; 
      }


      &:hover {
        >.dropdown-menu {
          transition: .5s;
          opacity:1;
          visibility:visible;
          top:100%;
          transform: rotateX(0deg);
        }
        & .dropdown-toggle::after {
          transform: rotate(180deg);
          transition: transform .3s ease-out; 
        }
      }
      >.dropdown-toggle:active {
          pointer-events: none;
      }
    } 

  }


  & .language svg.language {
    width: 23px;
    height: 23px;
    margin-top: -2px;
    cursor: pointer;
  }
  & .dropdown-menu {
    margin-top: 1em;
    &:before, &:after {
      content: ' ';
      display: block;
      border-style: solid;
      border-width: 0 .5em .8em .5em;
      border-color: transparent;
      position: absolute;
      left: 1em;
    }
    &:before {
      top: -.8em;
      border-bottom-color: rgba(0,0,0,0.5);
    }
    &:after {
      top: -.7em;
      border-bottom-color: #fff;
    }
    & img {
      vertical-align: inherit;
    }
  }
}
