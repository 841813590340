.blogitems-image {
	position:relative;
	overflow: hidden;
	padding-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 10px;
	margin: auto;
	width: 100%;
	height: 180px;
	text-align: center;
	& img, & picture {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
    	-webkit-transform: translateX(-50%) translateY(-50%);
    	-moz-transform: translateX(-50%) translateY(-50%);
		width: auto;
		height: 100%;
		margin: auto;
		padding-top: 10px;
    	padding-bottom: 10px;
	}
	&:after {
		content: " ";
		position: absolute;
		top: 92%;
		left: 0;
		height: 100%;
		width: 120%;
		background: white;
		-webkit-transform: rotate(4deg);
		-moz-transform: rotate(-4deg);
		transform: rotate(-4deg);
	}
}

.blogitems-description {
	min-height: 420px;
    padding: 20px 20px 82px 20px;
    height: calc(100% - 92px);
    text-align: left;
    border-radius: 0 0 6px 6px;
}

.blogitems-card {
	height: auto;
    border-bottom: none;
	text-align: right !important;
	margin-top: 0;
    margin-left: 0;
    border-radius: 5px;
    width: 100%;
    & span {
		line-height: 18px;
    	padding: 5px;
    	border-radius: 5px;
    	font-size: 14px;
    	font-weight: 600;
    	text-decoration: none;
    	display: inline-block;
    }
}

.blogitems-card-color-blue {
	& span {
		background-color: $bg-blue;
    	color: $blue;
	}
}

.blogitems-card-color-indigo {
	& span {
	    background-color: $bg-indigo;
    	color: $indigo;
    }
}

.blogitems-card-color-purple {
	& span {
    	background-color: $bg-purple;
    	color: $purple;
    }
}

.blogitems-card-color-pink {
	& span {
    	background-color: $bg-pink;
    	color: $pink;
    }
}

.blogitems-card-color-red {
	& span {
    	background-color: $bg-red;
    	color: $red;
    }
}

.blogitems-card-color-orange {
	& span {
    	background-color: $bg-orange;
    	color: $orange;
    }
}

.blogitems-card-color-yellow {
	& span {
    	background-color: $bg-yellow;
    	color: $yellow;
    }
}

.blogitems-card-color-green {
	& span {
    	background-color: $bg-green;
    	color: $green;
	}
}

.blogitems-card-color-teal {
	& span {
	    background-color: $bg-teal;
    	color: $teal;
    }
}

.blogitems-card-color-cyan {
	& span {
	    background-color: $bg-cyan;
    	color: $cyan;
    }
}

.blogitems-card-color-white {
	& span {
	    background-color: $bg-cyan;
    	color: $white;
	}
}

.blogitems-card-color-black {
	& span {
	    background-color: $bg-cyan;
    	color: $black;
	}
}

.blogitems-item {
	& h2 {
		text-align: center;
		font-size: 24px;
    	font-weight: bold;
    	line-height: 1.33;
    	letter-spacing: -0.3px;
    	cursor: pointer;
    	margin: 0;
    	text-shadow: none;
	}
	& div {
		align-items: center;
		text-align: center;
		margin:  auto;
	}
	& .blogitems-smalltxt {
		padding-top: 10px;
	}
	& a {
    	display: inline-block !important;
    	margin: 0% 0% 32px 0%;
    	vertical-align: top;
    	text-decoration: none;
    	position: relative;
    	overflow: visible;
    	min-height: 300px;		
	}
	& a:hover .blogitems-readmore {
		opacity:1;
	}
	& a:hover .blogitems-inner {
		-moz-transform: translateY(-60px);
		-webkit-transform: translateY(-60px);
		transform: translateY(-60px);
		transition: transform .3s ease-out;
	} 
	& .blogitems-inner {
		overflow: hidden;
		-moz-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
		transition: transform .3s ease-out;
	}
}

.blogitems-footer {
	position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;
    text-align: left !important;
	height: 64px;
    background-color: #fafafa;
    border-top: 1px solid #ededed;
}

.blogitems-details {
	display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 92px);
    margin-left: 16px !important;
    text-align: left !important;
    & .blogitems-author-name {
		font-size: 16px;
    	font-weight: bold;
        font-style: normal;
    	font-stretch: normal;
    	line-height: 1.44;
    	letter-spacing: normal;
    	color: $primary;
    }
    & .blogitems-date {
		font-size: 12px;
    	font-style: normal;
    	font-stretch: normal;
    	line-height: 1.44;
    	letter-spacing: 0.3px;
    	color: $gray-500;
	}
}

.blogitems-author-avatar {
	background-size: 64px auto;
    background-position: center center;
    background-repeat: no-repeat;
    height: 64px;
    border-radius: 32px;
    width: 64px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 14px !important;
    -moz-transform: translateY(-16px);
    -webkit-transform: translateY(-16px);
    transform: translateY(-16px);
}

.blogitems-readmore {
	font-size: 14px;
  	font-weight: normal;
  	line-height: 1.43;
  	letter-spacing: 0.2px;
  	text-align: center;
  	position: absolute;
  	bottom: 0;
  	left: 0;
    width: 100%;
  	opacity: 0;
  	-moz-transition: opacity .3s ease-out;
  	-webkit-transition: opacity .3s ease-out;
  	transition: opacity .3s ease-out;
  	& span {
		display:inline-block;
		padding-bottom:8px;
		border-bottom:1px solid #555555;
	}
}

.blogitems-readmore-arrow {
	background-image:url('/images/readmore-arrow.svg');
	background-position:center center;
	background-repeat:no-repeat;
	height:24px;
}

.blogitem-eventdate {
	background-image:url('/images/event.svg');
	background-position:0px 0px, center;;
	background-repeat:no-repeat;
	background-size: contain;
	width: 80px;
	height:80px;
	position: absolute;
	top: -2px;
	left: -2px;
	z-index: 10;
	display: table;
	& span {
		text-align: center;
    	font-size: 24px;
    	font-weight: bold;
		vertical-align: middle;
    	line-height: 1.05;
    	letter-spacing: -0.3px;
    	color:  $blue;
    	height: 80px;
    	padding-top: 25px;
    	display: table-cell;
	}
}

.blogitems-subscribe-newsletter {
	max-width: 350px;
	margin: auto;
	color: $gray-500;
	& .blogitems-subscribenb-inputwrapper {
		text-align: left;
		height: 48px;
	}
	& form.blogitems-subscribenb-form input[type="email"] {
    	text-align: center;
    	border: 1px solid rgba(0, 0, 40, 0.04);
    	border-radius: 4px 0 0 4px;
    	padding: 8px 4px;
    	width: 100% !important;
    	height: 48px;
    	margin: 0;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	box-sizing: border-box;
    	display: inline-block;
    	float: left;
		color: $gray-600;
		font-style: normal;
    	font-variant: normal;
    	font-weight: 400;
    	font-size: 18px;
    	line-height: 1.44;
    	&:focus, &:focus-visible {
			outline: $gray-200 auto 2px;
			box-shadow: initial;
		}
    }

    & form.blogitems-subscribenb-form .input-group {
    	width: calc(100% - 44px) !important;
    	float: left;
    }

    & form.blogitems-subscribenb-form input[type="email"].blogitems-subscribermail {
		opacity:  0;
		width: 0px !important;
		height: 0px;
		padding: 0 !important;
		margin: 0 !important;
		border:  none;
    }
    & button.blogitems-subscribenb-button {
    	float: left;
    }
    & button.dataloading {
    	background-image: url('/images/loading.gif') !important;
    }

::-webkit-input-placeholder {
	color: #777777;
}
::-moz-placeholder {
	color: #777777;
}
:-ms-input-placeholder {
	color: #777777;
}
:-moz-placeholder {
	color: #777777;
}

}