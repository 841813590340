.twocolumns-image {
  display: flex;
  align-items: left;
  flex-direction: row;
  text-align: center;
  //width: 50px;
  //height: 50px;"
  img, picture {
    width: 100%;
    height: auto;

  }
}

.strip {
  & .twocolumns {
    & .left {
      background-image: url('/images/product-feature-bg-left.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: calc(50% - 570px);
      background-position-y: top
    }  
    & .right {
      background-image: url('/images/product-feature-bg-right.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: calc(50% + 570px);
      background-position-y: top
    } 
  }
}

.twocolumns {
  margin-top: 40px;
  & .right {
    margin-right: 64px;
  }
  & .left {
    margin-left: 64px;
  }
  & div {
    align-items: center !important;
  }
}