.blogtitle-author-avatar {
	background-size: 64px auto;
    background-position: center center;
    background-repeat: no-repeat;
    height: 64px;
    border-radius: 32px;
    width: 64px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 14px !important;
}

.blogtitle-author-name {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.55;
    letter-spacing: normal;
    color: $primary;
    display: flex;
    margin-left: 10px;

}

.blogtitle-meta {
    display: flex;
    & .blogtitle-date {
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.66;
        letter-spacing: 0.3px;
        color: $gray-500;
        display:  flex;
        width: 100%;
    }

}

.blogtitle-card {
    height: auto;
    border-bottom: none;
    text-align: right !important;
    margin-top: 0;
    margin-left: 0;
    border-radius: 5px;
    width: 100%;
    & a {
        line-height: 22px;
        padding: 5px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        display: inline-block;
        &:link, &:visited, &:hover, &:active {
            text-decoration: none;        
        }
    }
}

.blogtitle-card-color-blue {
    & a {
        background-color: $bg-blue;
        color: $blue;
    }
}

.blogtitle-card-color-indigo {
    & a {
        background-color: $bg-indigo;
        color: $indigo;
    }
}

.blogtitle-card-color-purple {
    & a {
        background-color: $bg-purple;
        color: $purple;
    }
}

.blogtitle-card-color-pink {
    & a {
        background-color: $bg-pink;
        color: $pink;
    }
}

.blogtitle-card-color-red {
    & a {
        background-color: $bg-red;
        color: $red;
    }
}

.blogtitle-card-color-orange {
    & a {
        background-color: $bg-orange;
        color: $orange;
    }
}

.blogtitle-card-color-yellow {
    & a {
        background-color: $bg-yellow;
        color: $yellow;
    }
}

.blogtitle-card-color-green {
    & a {
        background-color: $bg-green;
        color: $green;
    }
}

.blogtitle-card-color-teal {
    & a {
        background-color: $bg-teal;
        color: $teal;
    }
}

.blogtitle-card-color-cyan {
    & a {
        background-color: $bg-cyan;
        color: $cyan;
    }
}

.blogtitle-card-color-white {
    & a {
        background-color: $bg-cyan;
        color: $white;
    }
}

.blogtitle-card-color-black {
    & a {
        background-color: $bg-cyan;
        color: $black;
    }
}

h1.blogtitle-heading {
    margin-top: 20px;
    font-size:  2.5em;
}

a.blogtitle-author {
    display:  flex;

    &:link, &:visited, &:hover, &:active {
        text-decoration: none;        
    }
}
